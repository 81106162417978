import RestService from './RestService';
import IdLessRestService from './IdLessRestService';

class TrialPlanService extends IdLessRestService {
    constructor(parentService) {
        if (parentService) {
            super(`${parentService.baseUrl}/trialPlan`);
        } else throw new Error('Can be only nested service');
    }
}

export default class SystemService extends RestService {
    constructor() {
        super('/system');
    }

    listHooks() {
        return this.get(`${this.baseUrl}/hooks`);
    }

    createHooks() {
        return this.post(`${this.baseUrl}/hooks`);
    }

    impersonate(email, companyId) {
        return this.get(`${this.baseUrl}/impersonate/${companyId}/${email}`);
    }

    invalidateCache(key) {
        return this.del(`${this.baseUrl}/cache/${key}`);
    }

    invalidateSystemSettings() {
        return this.del(`${this.baseUrl}/cache/system`);
    }

    invalidateAccountSettings(accountId) {
        return this.del(`${this.baseUrl}/cache/account/${accountId}`);
    }

    invalidateCompanySettings(companyId) {
        return this.del(`${this.baseUrl}/cache/company/${companyId}`);
    }

    readCache(key) {
        return this.get(`${this.baseUrl}/cache/${key}`);
    }

    readSystemSettings() {
        return this.get(`${this.baseUrl}/cache/system`);
    }

    readCompanySettings(companyId) {
        return this.get(`${this.baseUrl}/cache/company/${companyId}`);
    }
    readAccountSettings(accountId) {
        return this.get(`${this.baseUrl}/cache/account/${accountId}`);
    }

    trialPlanService() {
        return new TrialPlanService(this);
    }
}
