import React from 'react';
import moment from 'moment';

export function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

export function backOffDelay(
    baseDelay,
    counter,
    maxDelay = 240000,
    factor = 2
) {
    const jitter = getRandomArbitrary(0, 0.1);

    let delay = Math.min(baseDelay * Math.exp(counter / factor), maxDelay);
    delay = Math.round(delay * jitter);
    return delay;
}

export function trainingName(name, created) {
    return name || `Training program created on ${formatDate(created)}`;
}

export function percentToColor(value) {
    value = value || 0;
    if (value < 20) return 'error';
    if (value > 70) return 'success';
    return 'warning';
}

export function enforceSlash(url) {
    return url.endsWith('/') ? url : url + '/';
}

export function extractErrorMessage(error) {
    if (error === false || error === true || error == null) return;
    if (error && error.statusCode && error.data) {
        return error.data.error.description;
    }

    let responseData =
        error.response && error.response.data && error.response.data;
    if (
        responseData &&
        responseData.errors &&
        Array.isArray(responseData.errors)
    ) {
        return responseData.errors
            .map((e) => {
                if (e.messages) return e.messages.join(';');
                return e.message;
            })
            .join('\n');
    }

    return (responseData && responseData.error) || error.message;
}

export function querystring(name, url = window.location.href) {
    name = name.replace(/[[]]/g, '\\$&');

    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i');
    const results = regex.exec(url);

    if (!results) {
        return null;
    }
    if (!results[2]) {
        return '';
    }

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function guid() {
    return (
        this.randomString4() +
        this.randomString4() +
        '-' +
        this.randomString4() +
        '-' +
        this.randomString4() +
        '-' +
        this.randomString4() +
        '-' +
        this.randomString4() +
        this.randomString4() +
        this.randomString4()
    );
}

export function randomString4() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

export function currentDomain() {
    // check if running on server
    if (!(typeof window != 'undefined' && window.document)) {
        // running on server, can use server variables
        return import.meta.env.SERVER_URL;
    } else {
        // we are running on client and can use location
        return (
            window.location.protocol +
            '//' +
            window.location.hostname +
            (window.location.port ? ':' + window.location.port : '')
        );
    }
}

export function validateEmail(email) {
    var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line  no-useless-escape
    return re.test(String(email).toLowerCase());
}

export function formatTime(seconds, round) {
    let duration = moment.duration(seconds, 'seconds');
    if (round) {
        return Math.ceil(duration.asMinutes());
    } else {
        return duration.format();
    }
}

export function formatStatus(status) {
    switch (status) {
        case 0:
            return 'Scheduled';
        case 1:
            return 'Running';
        case 2:
            return <span style={{ color: 'green' }}>Success</span>;
        case 3:
            return <span style={{ color: 'red' }}>Error</span>;
    }
}

export function formatDate(dateString) {
    if (!dateString) {
        return '';
    }
    try {
        const dt = new Date(dateString);
        const monthNames = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];
        return (
            monthNames[dt.getMonth()] +
            ' ' +
            dt.getDate() +
            ' ' +
            dt.getFullYear()
        );
    } catch (e) {
        return '';
    }
}

export function formatFrequency(code) {
    switch (code.toUpperCase()) {
        case 'W1':
            return '1 per week';
        case 'W2':
            return '2 per week';
        case 'W3':
            return '3 per week';
        case 'W4':
            return '4 per week';
        case 'W5':
            return '5 per week';
        case 'W6':
            return '6 per week';
        case 'W7':
            return '7 per week';
        case 'W8':
            return '8 per week';
        case 'W9':
            return '9 per week';
        case 'M1':
            return '1 per month';
        case 'M2':
            return '2 per month';
        case 'M3':
            return '3 per month';
        case 'M4':
            return '4 per month';
        case 'M5':
            return '5 per month';
        case 'M6':
            return '6 per month';
        case 'M7':
            return '7 per month';
        case 'M8':
            return '8 per month';
        case 'M9':
            return '9 per month';
        default:
            return code;
    }
}

export function formatWeekday(weekday) {
    switch (weekday) {
        case 1:
            return 'Monday';
        case 2:
            return 'Tuesday';
        case 3:
            return 'Wednesday';
        case 4:
            return 'Thursday';
        case 5:
            return 'Friday';
        case 6:
            return 'Saturday';
        case 7:
            return 'Sunday';
        default:
            return weekday;
    }
}

export function formatTimeOfDay(time) {
    if (time !== '') {
        return time + '.00 GMT';
    }
    return time;
}

export function getWeekday(dateObject) {
    if (!dateObject) {
        return 'null';
    }
    try {
        const weekday = [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
        ];
        let weekDay = weekday[dateObject.getDay()];
        return weekDay;
    } catch (e) {
        return e.description;
    }
}

export function getHours(dateObject) {
    if (!dateObject) {
        return 'null';
    }
    try {
        return (
            dateObject.getHours() +
            ':' +
            (dateObject.getMinutes() < 10 ? '0' : '') +
            dateObject.getMinutes()
        );
    } catch (e) {
        return e.description;
    }
}

//TODO: use querystring instead
export function buildUrl(url, parameters) {
    let qs = '';
    for (let key in parameters) {
        const value = parameters[key];
        qs += encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&';
    }
    if (qs.length > 0) {
        qs = qs.substring(0, qs.length - 1); //chop off last "&"
        url = url + '?' + qs;
    }
    return url;
}

//TODO: use querystring instead
export function buildXtraParameters(company_id, campaign_id, subject_id) {
    let xtra_params = '';
    if (company_id && company_id > 0) {
        xtra_params += 'o=' + company_id;
    }
    if (campaign_id && campaign_id > 0) {
        if (xtra_params.length > 0) {
            xtra_params += '&';
        }
        xtra_params += 'c=' + campaign_id;
    }
    if (subject_id && subject_id > 0) {
        if (xtra_params.length > 0) {
            xtra_params += '&';
        }
        xtra_params += 's=' + subject_id;
    }
    return xtra_params;
}

export function host() {
    return (
        window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '')
    );
}

export function alert(message) {
    console.info(message);
}

export function t(store, key) {
    const i18n = store ? store.i18n : null;
    if (i18n) {
        return i18n.t(key);
    }
    return key;
}

// when htmlField is empty, its value is <p><br></p>, but we want it to be an empty string so it does not do bad things to email templates, also it is necessary to fix non https links because links without https made in react-rte do not work
export function fixHtml(value) {
    if (value === '<p><br></p>') {
        return '';
    } else {
        return value.replace('"www', '"https://www');
    }
}
