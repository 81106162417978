import CommonStore from './commonStore';
import SuperStore from './superStore';
import AuthStore from './authStore';
import VideosStore from './videosStore';
import BuildsStore from './buildsStore';
import ScheduleStore from './scheduleStore';
import NotificationsStore from './notificationsStore';
import ScheduledTasksStore from './scheduledTasksStore';
import ServiceTasksStore from './serviceTasksStore';
import TagsStore from './tagsStore';
import ThreatAreasStore from './threatAreaStore';
import IntegrationsStore from './integrationsStore';

const commonStore = new CommonStore();

const stores = {
    authStore: new AuthStore(commonStore),
    commonStore,
    superStore: new SuperStore(),
    videosStore: new VideosStore(),
    buildsStore: new BuildsStore(),
    scheduleStore: new ScheduleStore(),
    notificationsStore: new NotificationsStore(),
    scheduledTasksStore: new ScheduledTasksStore(),
    serviceTasksStore: new ServiceTasksStore(),
    tagsStore: new TagsStore(),
    threatAreasStore: new ThreatAreasStore(),
    integrationsStore: new IntegrationsStore(),
};

export default stores;
