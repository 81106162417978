import React, { Fragment, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { formatDate } from '../../utils/helpers';
import { useStores } from '../../hooks/use-stores';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import SimpleTable from '../../components/super/SimpleTable';
import { FormControlLabel, Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Fab } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import GreenCheckbox from '../../components/GreenCheckbox';
import Button from '@material-ui/core/Button';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
}));

function PartnersList() {
    const { superStore } = useStores();
    const { loadingPartners, partners, metricDates } = superStore;
    const classes = useStyles();
    const [activeOnly, setActiveOnly] = React.useState(true);
    const [withCompanies, setWithCompanies] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [selectedDateError, setSelectedDateError] = React.useState(null);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        superStore.loadPartners();
    };

    const handleChangeActiveOnly = (event) => {
        setActiveOnly(event.target.checked);
    };

    const handleChangeWithCompanies = (event) => {
        setWithCompanies(event.target.checked);
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                Cell: (x) => (
                    <Link to={`/partners/${x.row.original.id}`}>{x.value}</Link>
                ),
                accessor: 'name',
            },
            {
                Header: 'Created',
                accessor: 'created',
                Cell: (x) => formatDate(x.value),
            },
            {
                Header: 'Managers',
                accessor: (x) =>
                    x.managers.map((manager) => (
                        <Fragment key={manager}>
                            {manager}
                            <br />
                        </Fragment>
                    )),
            },
            {
                Header: 'LMS domain',
                accessor: 'lms_domain',
                /*(x) =>
                    x.lms_domain.map((domain) => (
                        <Fragment  key={domain}>
                            {domain}
                            <br />
                        </Fragment>
                    )),*/
            },
            {
                Header: 'MY domain',
                accessor: 'my_domain',
                /*(x) =>
                    x.my_domain.map((domain) => (
                        <Fragment  key={domain}>
                            {domain}
                            <br />
                        </Fragment>
                    )),*/
            },
            {
                Header: 'Mail domain',
                accessor: 'mailgun_domain',
                /*(x) =>
                    x.mailgun_domain.map((domain) => (
                        <Fragment key={domain}>
                            {domain}
                            <br />
                        </Fragment>
                    )),*/
            },
            {
                Header: 'Companies',
                accessor: 'company_count',
            },
        ],
        []
    );

    const handleExport = () => {
        if (!selectedDate) {
            return setSelectedDateError('Please select a date');
        }
        setSelectedDateError(null);
        superStore.exportPartners({
            activeOnly,
            selectedDate: selectedDate.toDate(),
            withCompanies,
        });
    };

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2">Partners</Typography>
                </Box>
            </Grid>
            {loadingPartners ? (
                <ThemedSpinner />
            ) : (
                <Fragment>
                    <Tooltip
                        title="Create partner"
                        aria-label="add"
                        placement="left"
                    >
                        <Fab
                            color="primary"
                            href="/partners/create"
                            className={classes.fab}
                        >
                            <AddIcon />
                        </Fab>
                    </Tooltip>

                    {partners && partners.length > 0 && (
                        <Grid item xs={12}>
                            <SimpleTable
                                columns={columns}
                                data={partners.filter((p) => {
                                    return activeOnly ? p.enabled === 1 : true;
                                })}
                                needsFilter={true}
                                pageSize={100}
                                extraFilter={
                                    <>
                                        <FormControlLabel
                                            control={
                                                <GreenCheckbox
                                                    checked={activeOnly}
                                                    onChange={
                                                        handleChangeActiveOnly
                                                    }
                                                    name="checkedG"
                                                />
                                            }
                                            label="Active only"
                                        />
                                        <Box
                                            display={'flex'}
                                            flexDirection={'column'}
                                        >
                                            <LocalizationProvider
                                                dateAdapter={AdapterMoment}
                                            >
                                                <DatePicker
                                                    value={selectedDate}
                                                    onChange={(newValue) => {
                                                        setSelectedDate(
                                                            newValue
                                                        );
                                                    }}
                                                    shouldDisableDate={(
                                                        day
                                                    ) => {
                                                        const candidate = `${day.year()}-${
                                                            day.month() + 1
                                                        }-${day
                                                            .date()
                                                            .toString()
                                                            .padStart(2, '0')}`;
                                                        return !metricDates.includes(
                                                            candidate
                                                        );
                                                    }}
                                                    helperText={
                                                        selectedDateError
                                                    }
                                                    error={!!selectedDateError}
                                                />
                                            </LocalizationProvider>
                                            {selectedDateError && (
                                                <Typography
                                                    color={'error'}
                                                    variant={'caption'}
                                                >
                                                    {selectedDateError}
                                                </Typography>
                                            )}
                                        </Box>
                                        <Button
                                            variant={'contained'}
                                            color={'primary'}
                                            onClick={handleExport}
                                        >
                                            Export
                                        </Button>
                                        <FormControlLabel
                                            control={
                                                <GreenCheckbox
                                                    checked={withCompanies}
                                                    onChange={
                                                        handleChangeWithCompanies
                                                    }
                                                    name="checkedG"
                                                />
                                            }
                                            label="With Companies"
                                        />
                                    </>
                                }
                            />
                        </Grid>
                    )}
                </Fragment>
            )}
        </Grid>
    );
}

export default observer(PartnersList);
